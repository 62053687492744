
.card-wrapper {
	display: inline-block;
	vertical-align: text-top;
	width: auto;
	margin: 10px;
	padding: 10px;
	border: 1px solid grey;
	border-radius: 5px;
}

.card-colorblock-wrapper {
	vertical-align: top;
	display: inline-block;
	padding: 5px;
}
.card-colorblock {
	display: inline-block;
	border: 1px solid black;
	margin: 5px;
	height: 30px;
	width: 30px;
}

.card-label {
	font-size: x-large;
	padding: 3px;
}

.card-buttons {
	padding: 10px;
}